import { createAction, props } from '@ngrx/store';
import { DashboardFilters } from 'src/types';

// eslint-disable-next-line no-shadow
export const enum DashboardActions {
  LoadDashboardVoyages = '[DASHBOARD] load Dashboard Voyages',
  LoadDashboardVoyagesSuccess = '[DASHBOARD] load Dashboard Voyages Success',
  LoadDashboardVoyagesFailure = '[DASHBOARD] load Dashboard Voyages Failure',
  LoadDashboardCompanies = '[DASHBOARD] load Dashboard Companies',
  LoadDashboardCompaniesSuccess = '[DASHBOARD] load Dashboard Companies Success',
  LoadDashboardCompaniesFailure = '[DASHBOARD] load Dashboard Companies Failure',
  LoadDashboardAnomalies = '[DASHBOARD] load Dashboard Anomalies',
  LoadDashboardAnomaliesSuccess = '[DASHBOARD] load Dashboard Anomalies Success',
  LoadDashboardAnomaliesFailure = '[DASHBOARD] load Dashboard Anomalies Failure',
  SetDashboardFilters = '[DASHBOARD] Set Dashboard Filters',
}

/** Load dashboard summary */
export const loadDashboardVoyages = createAction(DashboardActions.LoadDashboardVoyages, props<{ filters?: any }>());
export const loadDashboardVoyagesSuccess = createAction(DashboardActions.LoadDashboardVoyagesSuccess, props<{ data: any }>());
export const loadDashboardVoyagesFailure = createAction(DashboardActions.LoadDashboardVoyagesFailure, props<{ error: any }>());

/** Load dashboard companies */
export const loadDashboardCompanies = createAction(DashboardActions.LoadDashboardCompanies, props<{ filters?: any }>());
export const loadDashboardCompaniesSuccess = createAction(DashboardActions.LoadDashboardCompaniesSuccess, props<{ data: any }>());
export const loadDashboardCompaniesFailure = createAction(DashboardActions.LoadDashboardCompaniesFailure, props<{ error: any }>());

/** Load dashboard anomalies */
export const loadDashboardAnomalies = createAction(DashboardActions.LoadDashboardAnomalies, props<{ filters?: any }>());
export const loadDashboardAnomaliesSuccess = createAction(DashboardActions.LoadDashboardAnomaliesSuccess, props<{ data: any }>());
export const loadDashboardAnomaliesFailure = createAction(DashboardActions.LoadDashboardAnomaliesFailure, props<{ error: any }>());

/** Dashboard filters */
export const setDashboardFilters = createAction(DashboardActions.SetDashboardFilters, props<{ filters: Partial<DashboardFilters>, loadCount?: boolean }>());