import { createSelector } from '@ngrx/store';
import { AppState } from '..';
import { camelToSnakeCase } from 'src/utils';
import { anomaliesTypes, voyageStatusList } from 'src/directories';
import { selectOpsUsers } from './user.selectors';
import { selectUser } from './auth.selectors';

export const dashboardState = (state: AppState) => state.dashboard;

export const selectDashboardVoyages = createSelector(dashboardState, ({ voyages = {} }) => {
  const voyageStatus = voyages
    ? Object.keys(voyages)
      .filter((key) => key !== 'allVoyages')
      .map((key) => {
        const formattedKey = camelToSnakeCase(key);
        const status = voyageStatusList.find((x) => x.value === formattedKey);
        return {
          label: status?.label || key,
          value: voyages[key],
        };
      })
    : [];

  return { voyages, voyageStatus };
});

export const selectDashboardCompanies = createSelector(dashboardState, (data) => ({
  datasets: data?.companies.map((company: any) => company?.voyages),
  labels: data?.companies.map((company: any) => company?.name),
}));

export const selectDashboardFilter = createSelector(
  dashboardState,
  selectOpsUsers,
  selectUser,
  ({ filters: newFilters, anomalies }, opsUsers, currentUser) => {
    const defaultAssignedTo = opsUsers && opsUsers.find((user) => user?.email === currentUser?.email);
    const defaultAnomalyType = anomaliesTypes.find((type) => type.name === 'All anomalies');
    const firstName = currentUser?.name?.split(' ')[0];

    const filters: any = {
      assigned_to: [defaultAssignedTo?.email || ''],
      company: [''],
      anomaly_types: [defaultAnomalyType?.value || ''],
      include_verified_voyages: false,
      firstName,
      sort_order: '',
      voyage_status: [],
      search: '',
      startDate: '',
      endDate: '',
      startIndex: 0,
      endIndex: 20,
      totalAnomalies: anomalies?.length,
      ...newFilters,
    };

    return filters;
  }
);

export const selectDashboardAnomalies = createSelector(
  dashboardState,
  selectDashboardFilter,
  (data, { startIndex = 0, endIndex = 20 }) => data?.anomalies?.slice(startIndex, endIndex)
);

export const selectDashboardLoading = createSelector(dashboardState, (loading) => loading?.loading);
